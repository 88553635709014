<template>
  <div class="flex justify-center h-full bg-gray-600 items-center">
    <div class="p-2 w-full h-full md:w-1/2 grid grid-cols-3 gap-4">
      <TileItem
        v-for="tile in tiles"
        :key="tile.id"
        :label="tile.label"
        :image-url="tile.imageUrl"
        :dest="tile.dest"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import TileItem from "@/components/TileItem.vue";

const tiles = ref([
  {
    id: 1,
    label: "Websites",
    imageUrl: require("@/assets/castle.png"),
    dest: "/websites",
  },
  {
    id: 2,
    label: "About",
    imageUrl: require("@/assets/eye.png"),
    dest: "/about",
  },
  {
    id: 3,
    label: "Art",
    imageUrl: require("@/assets/robot.png"),
    dest: "/art",
  },
  {
    id: 4,
    label: "Advice",
    imageUrl: require("@/assets/tech.png"),
    dest: "/techconsult",
  },
  {
    id: 5,
    label: "Contact",
    imageUrl: require("@/assets/dish.png"),
    dest: "/contact",
  },
  {
    id: 6,
    label: "Mentorship",
    imageUrl: require("@/assets/brain.png"),
    dest: "/mentorship",
  },
]);
</script>
