<template>
  <div
    class="text-lg text-center h-full flex flex-col items-center justify-evenly"
  >
    <h1 class="antialiased text-6xl font-black text-gray-300 py-2 my-2">
      <slot name="heading"></slot>
    </h1>
    <div class="w-full">
      <div
        class="w-[90%] p-4 md:w-1/2 lg:w-1/3 mx-auto shadow-lg bg-gray-800 text-gray-300 rounded-lg"
      >
        <slot name="text"></slot>
      </div>
    </div>
  </div>
</template>

<script setup></script>
