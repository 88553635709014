<template>
  <div
    @click="handleClick"
    class="tile rounded-lg overflow-hidden"
    :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
  >
    <div
      class="w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-30 hover:bg-opacity-5"
    >
      <div class="label hover:text-green-500">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    default: "",
  },
  dest: {
    type: String,
    default: "",
  },
});

const router = useRouter();


const handleClick = () => {
  router.push(props.dest)
  // Perform any other logic you need to here
};
</script>

<style scoped>
.tile {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
  transform-origin: center center;
}
/* 
@media (min-width: 768px) {
  .tile {
    width: 250px;
    height: 250px;
  }
} */

.tile:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}

.label {
  position: absolute;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
