<template>
  <TextBlob>
    <template #heading>
      <div>
        <span ref="growText" id="grow-text">Strive</span>
      </div>
    </template>
    <template #text>
      My playground and toolbox.
    </template>
  </TextBlob>
</template>

<script setup>
import TextBlob from "@/components/TextBlob.vue";

const colors = [
  "#00ff00",
  "#0000ff",
  "#ffff00",
  "#ff00ff",
  "#00ffff",
  "#ff0000",
  "#800000",
  "#008000",
  "#000080",
  "#808000",
  "#800080",
  "#008080",
  "#ff8000",
  "#ff0080",
  "#80ff00",
  "#80ff80",
  "#0080ff",
  "#8000ff",
  "#ff80ff",
  "#808080",
  "#c0c0c0",
  "#ff9999",
  "#99ff99",
  "#9999ff",
  "#ffff99",
  "#ff99ff",
  "#99ffff",
  "#cc0000",
  "#00cc00",
  "#0000cc",
  "#cccc00",
  "#cc00cc",
  "#00cccc",
  "#ffcc00",
  "#ff00cc",
  "#ccff00",
  "#ccffff",
  "#00ffcc",
  "#ffccff",
  "#a52a2a",
  "#7fff00",
];

let currentColorIndex = 0;

// Function to change the color of the Grow text
function changeGrowTextColor() {
  const growText = document.querySelector('#grow-text');
  if (growText) {
    growText.style.color = colors[currentColorIndex];
    currentColorIndex = (currentColorIndex + 1) % colors.length;
  }
}
// Set an interval to change the color every second
setInterval(changeGrowTextColor, 4000);
</script>

<style scoped>
#grow-text {
  transition: 4s color ease;
}
</style>
