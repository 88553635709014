import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
// import TicketBusterView from '../views/TicketBusterView.vue'
import WebsitesView from '../views/WebsitesView.vue'
import ContactView from '../views/ContactView.vue'
import TechConsultView from '../views/TechConsultView.vue'
import MentorshipView from '../views/MentorshipView.vue'
import ArtView from '../views/ArtView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/websites',
    name: 'websites',
    component: WebsitesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/techconsult',
    name: 'techconsult',
    component: TechConsultView
  },
  {
    path: '/mentorship',
    name: 'mentorship',
    component: MentorshipView
  },
  {
    path: '/art',
    name: 'art',
    component: ArtView
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
